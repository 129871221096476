.selectedLanguage {
  background-color: rgb(214, 214, 214) !important;
  color: black;
}

.navbar-toggler {
  left: 0 !important;
}


.paysimplyNavClass button.navbar-toggler {
  color: rgba(255, 255, 255, 1) !important;
  border-color: rgba(255, 255, 255, 1) !important;
}

.paysimplyNavClass button.navbar-toggler span.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}

.nowprepayNavClass button.navbar-toggler {
  color: rgba(0, 0, 0, 0.5) !important;
  border-color: rgba(0, 0, 0, 0.5) !important;
}

.nowprepayNavClass button.navbar-toggler span.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}

.topmeupNavClass button.navbar-toggler {
  color: rgba(255, 255, 255, 0.644) !important;
  border-color: rgba(255, 255, 255, 0.644) !important;
}

.topmeupNavClass button.navbar-toggler span.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 0.644)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}

.zenwalletNavClass button.navbar-toggler {
  color: rgba(255, 255, 255, 0.644) !important;
  border-color: rgba(255, 255, 255, 0.644) !important;
}

.zenwalletNavClass button.navbar-toggler span.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 0.644)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}

.langdrop1 a button {
  color: rgba(255, 255, 255, 1) !important;
  border-color: rgba(255, 255, 255, 1) !important;
}

.langdrop1 div a {
  color: rgba(255, 255, 255, 1) !important;
}

.langdrop1 div div a {
  color: rgb(0, 0, 0) !important;
}

.langdrop1 div a li {
  color: rgba(0, 0, 0, 1) !important;
}

.langdrop2 a button {
  color: #555556 !important;
  border-color: #555556 !important;
}

.langdrop2 div a {
  color: #555556 !important;
}

.langdrop2 div div a {
  color: rgba(32, 32, 32, 0.644) !important;
}

.langdrop3 a button {
  color: rgba(255, 255, 255, 0.644) !important;
  border-color: rgba(255, 255, 255, 0.644) !important;
}

.langdrop3 div a {
  color: rgba(255, 255, 255, 0.644) !important;
}

.langdrop3 div div a {
  color: rgba(32, 32, 32, 0.644) !important;
}

.langdrop4 a button {
  color: rgba(255, 255, 255, 0.644) !important;
  border-color: rgba(255, 255, 255, 0.644) !important;
}

.langdrop4 div a {
  color: rgba(255, 255, 255, 0.644) !important;
}

.langdrop4 div div a {
  color: rgba(0, 0, 0, 0.644) !important;
}

#basic-nav-dropdown {
  color: black;
}

.dropdown-menu {
  z-index: 999999;
}

.skip-link {
  color: #004fa3;
  background-color: #ffffff;
}

.mr-1 a button {
  background-color: rgba(0, 0, 0, 0);
  border: 2px solid !important;
  border-radius: 5px;
  //padding: 4px;
}

#navbar-logo {
  padding: 0;
  height: 30px;

  &[alt~="Direct"] {
    height: 40px;
  }
  &[alt~="Prepay"] {
    height: 50px;
  }

}

.navbar {
  &.nowprepay {
    padding: 0.5rem !important;
  }
  &.directpay {
    padding: 0.5rem !important;
  }
}

.language-dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529 !important;
  text-align: inherit;
  white-space: nowrap;
  //background-color: transparent;
  border: 0;
  text-decoration: none;
}

.language-dropdown {
  margin-right: 0.5em !important;
}

.language-dropdown-item:focus,
.language-dropdown-item:hover {
  color: #16181b !important;
  text-decoration: none;
  background-color: #e9ecef;
}

@media only screen and (min-width: 992px) {
  .navbar {
    padding: 1rem;
  }

  .navbar-expand-lg .navbar-collapse {
    position: absolute;
    right: 0;
  }
}

@media only screen and (min-width: 0px) and (max-width: 991px) {
  .navbar {
    padding: 0.5rem;
  }
  .navbar-toggler {
    margin-right: auto;
  }

  #navbar-branding {
    margin-right: auto;
    margin-left: -50px;
  }
}

.black-font, .black-font:hover {
  color: black;
}

.cust-contact-btn {
  border: solid 0.3px;
  border-radius: 4px;
  padding: 5px !important;
  margin-bottom: -5px;
}

.navbar-nav { // Entire navbar buttons
  padding-bottom: 1px;
}

.nav-item {
  margin-right: 20px;
  margin-bottom: 5px;
  padding-top: 10px;
}

.contact-us-btn {
  padding-top: 7px;
}

#navbarLogoText {
  margin-left: 5px;
  font-weight: 500;
  position: relative;
  top:5px;
}
