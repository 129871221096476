.custom-breadcrumb {
  padding-top: 0.75rem;
  display: inline-block !important; // added
}

.breadcrumb-text {
  // display: flex;
  display: inline-block !important; // added
  font-size: small;
  color: #222222;
}

.chevron-style {
  font-size: small;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 1.2px;
  vertical-align: middle;
}

.breadcrumb-text ol {
  display: inline-block !important; // added
  margin: 0;
  padding-left: 0;
  list-style: none;
}

.breadcrumb-text li {
  display: inline;
}

/*This is for desktop (greater than cellphone based on iPhone 12 promax 486px) */
@media only screen and (min-width: 705px) {
  .custom-breadcrumb {
    padding-left: 2rem;
  }
}

/*This is for mobile devices*/
@media only screen and (max-width: 704px) {
  .custom-breadcrumb {
    padding-left: 0.5rem;
  }
}

#breadcrumbs {
  display: inline-block !important; // added
}
