#howhelp {
  font-size: 30px;
  color: #ffffff;
}

.banner {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)),
    url("https://psinet-web-pub.s3.ca-central-1.amazonaws.com/Shared/Help-Centre-Hero.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-bottom: 8vh;
}

#search {
  padding: 5px 0;
  width: 70%;
  resize: none;
}

#howCanWeHelpID {
  color: white;
  text-align: center;
  padding-top: 5.5vh;
  padding-bottom: 3.5vh;
  font-size: 2rem;
  font-weight: 300 !important;
  -webkit-font-smoothing: antialiased;
  margin: 0;
}

// .banner {
//   background-image: url("https://psinet-web-pub.s3.ca-central-1.amazonaws.com/Shared/Help-Centre-Hero.png");
//   width: 100%;
//   background-size: cover;
//   background-repeat: no-repeat;
//   overflow: hidden;
//   height: 264px;
//   text-align: center;
//   padding-top: 8%;
//   padding-bottom: 15%;
//   background-position: center;
// }

#searchMenu {
  display: block;
  background-color: rgba(0, 0, 0, 0.15);
  // box-shadow: 1px 3px 3px rgba(0,0,0,.3);
}

@media only screen and (min-width: 0px) and (max-width: 705px) {
  #howCanWeHelpID {
    font-size: 20px;
    text-align: center;
    font-weight: 300 !important;
    padding-top: 25px;
  }
  #searchMenu {
    display: none;
  }
  .banner {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)),
    url("https://psinet-web-pub.s3.ca-central-1.amazonaws.com/Shared/Help-Centre-Hero.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding-bottom: 3vh;
  }
}
