.feature-grid .fa-3x {
  color: var(--tm-green);
}

.feature-row h3 {
  font-size: 25px;
}

.feature-grid h2 {
  font-size: 30px;
}

@media screen and (max-width: 400px) {
  .feature-grid {
    margin-top: 25px !important;
  }

  .feature-row h3 {
    font-size: 20px;
  }

  .feature-row p {
    font-size: 15px;
  }
}