#category-flex-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.widget-card {
  text-align: center;
  margin: 5px;
}

.widget-card:hover {
  box-shadow: var(--card-hover-box-style);
  transition: 0.3s;
}

.widget-card-img {
  width: 60%;
}