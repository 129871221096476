.paysimply-radio {

  .select-option {
    color: black;
    border: 1px solid var(--ps-btn);
  }

  .contact-radio:checked ~ .select-option {
    color: white;
    background-color: var(--ps-btn) !important;
  }
}