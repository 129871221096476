.card :hover {
  text-decoration: none;
}

.mainDeck1 {
  text-align: center;
  color: black;
  padding: 1rem;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.card-style {
  min-width: 14rem;
  max-width: 14rem;
  display: flex;
  margin: 1rem;
  min-height: 8rem;
}

.card-desc {
  color: rgba(0,0,0,0.85);
}

.card-style:hover {
  box-shadow: -1px 0 5px 3px #d0d0d0;
  text-decoration: none !important;
}

.title-card {
  margin: 0;
  padding: 0;
}

.card-text-general {
  color: black;
  text-decoration: none;
}

.card-text*:hover,
.title-card:hover .card-desc.card-text:hover .card-desc*:hover,
.card-body*:hover,
.card-style*:hover,
.card-text-general:hover,
p:hover,
.card-text-general:hover {
  text-decoration: none !important;
}

//desktop
@media only screen and (min-width: 704px) {


  .desktop-icon {
    visibility: visible;
  }
  .mobile-icon {
    visibility: hidden;
  }

  #category-card-title {
    padding-top: 15px;
  }

  .card-desc {
    font-size: smaller;
  }

  .card-style {
    min-width: 19rem;

  }
  .categories-card-body {
    padding: 0 1rem 1rem;
  }
}

//Mobile
@media only screen and (max-width: 705px) {
  .desktop-icon {
    display: none;
  }
  .mobile-icon {
    visibility: visible;
    font-weight: 500;
  }

  #category-card-title {
    margin-top: 10px;
    font-size: small;
  }

  .card-desc {
    font-size: smaller;
    //color: red;
  }

  .categories-card-body {
    padding: 0.8rem;
  }
  .card-style {
    min-width: 12rem;
    max-width: 90vw;
    display: flex;
    margin: 1rem;
    min-height: 8rem;
  }
}

// Check status box. Appears after clicking payment verification
.div-check-status {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  border: 1px rgba(0,0,0,0.75) solid;
  border-radius: 0.25rem;
  margin-top: 30px;

  button, .btn {
    border-radius: 0.25rem !important;
    width: 185px;
    padding: 7px;
    font-size: smaller;
  }

  #status-top {
    display:flex;
    flex-direction: column;
    margin-bottom: 15px;

    span:first-child {
      font-weight: 400;
      font-size: 1.3rem;
    }
    span:nth-child(2) {
      font-size: smaller;
    }
  }

  #status-bottom {
    display: flex;
    justify-content: space-between;
  }
}
