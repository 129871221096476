.relevant-articles-title {
	font-size: 1.3em;
	font-weight: 400;
	margin: 0;
}

.relevant-articles-footer {
	@extend .relevant-articles-title;
}

.relevant-articles-footer-content {
	font-size: 0.85em;

	a {
		color: black;
		text-decoration: underline;
	}
}

.relevant-articles-card {
	margin-top: 0.75rem;
}

.faq-link:hover {
	box-shadow: -1px 0 5px 3px #d0d0d0;
}

.faq-link-text {
	text-decoration: none;
	font-size: 1em !important;
	margin: 0 !important;
	color: black;
}

.faq-list-group {
	background-color: #f7f7f7;
	
}

.faq-link-span {
	display:flex;
	justify-content: space-between;
	color: black;
	align-items: center;
}

.faq-breadcrumb {
	color: #3f3f3ff7;
	font-size: smaller;
}

.faq-card-body {
	padding: 0.75rem;
}

.faq-chevron {
	margin: 0 5px;
}
