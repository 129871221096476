.iframe-flex {
  display: flex;
  flex-direction: column;
}

.widget-main-content {
  padding-top: var(--content-padding-widget);
  padding-left: var(--content-padding-widget);
  padding-right: var(--content-padding-widget);
  margin-top: 135px;
  margin-bottom: 75px;
}
