.cx-webchat {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100% !important;
  //height: 92%;
  height: 100vh;
}

.widget-contact-us {
  display: flex;
  flex-direction: row;
  color: var(--hyperlink-grey);
  padding: 0.75rem;
  text-decoration: none;
  vertical-align: middle;
  margin-bottom: 15px;
  cursor: pointer;
}

.v-align {
  margin-top: auto;
  margin-bottom: auto;
  font-size: 18px;
}

.widget-contact-us:hover {
  text-decoration: none;
  color: var(--hyperlink-grey);
  border-radius: 3px;
  box-shadow: var(--card-hover-box-style);
  transition: 0.3s;
}

.widget-icon-contact {
  margin-right: 0.5rem;
}

.widget-file-icon {
  margin-right: 1rem;
  margin-left: 0.3rem;
}


#end-chat-btn {
  display: inline-block !important;
  z-index: 1000001;
  position: fixed;
  right: 24px;
  top: 15px;
  border: unset;
  background-color: transparent !important;
  border-radius: 2px;
  font-size: 1em;
  height: 30px;
  box-sizing: border-box;
  padding: 5px 24px;
  font-weight: 500;
}

.center-of-screen {
  visibility: hidden;
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 10%;
}

