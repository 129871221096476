// searchbox including sides
.widget-searchBox {
  display: flex;
  position: relative;
  justify-content: center;
  margin-top: 0.5rem;
}

// top div
.widget-ais-instantSearch {
  position: relative;
  width: 100%;
}

// search box left (change size of text input)
.ais-SearchBox-input {
  margin-left: 10px;
}

.widget-algolia-search-result-pulldown {
  display: flex;
  flex-direction: column;
  z-index: 9999;
  position: absolute;
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: calc(100% - 2rem);
}

.widget-search-result-card {
  color: var(--hyperlink-grey);
  margin: 3px 2px;
  font-size: small;
  transition: 0.3s;
  padding: 0.4rem;
  padding-left: 0.5rem;
  display: flex;
  flex-direction: column;
}

.widget-search-result-card:hover {
  box-shadow: -1px 0 5px 3px #b4b4b4;
  transition: 0.3s;
}

.widget-algolia-section {
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0.1px;
  opacity: 0.8;
  margin-bottom: 3px;
}

.widget-algolia-search-hits-item {
  border: dot-dash;
}

// Magnifying glass
.ais-SearchBox-submit {
  margin-top: -0.05rem;
  padding: 10px;
  cursor: default !important;

  &:hover {
    cursor: default !important;
  }
}

.bordered {
  border: 1.5px solid #c4c4c4 !important;
  border-radius: var(--border-radius-size);
}


.widget-mag-glass {
  color: #707070;
}

.ais-SearchBox-input::placeholder {
  color: #707070;
}

#search-icon:hover {
  cursor:default;
}
