:root {
  --border-radius-size: 0.5rem;
  //PaySimply
  --ps-red: #bb1b20;
  --ps-btn: #2b4e7b;

  //Zenwallet
  --zw-green: #73b842;

  //Topmeup
  --tm-green: #448003;

  //DirectPay
  --dp-blue: #13265c;
  --dp-green: #35AE47;
  --dp-btn: var(--dp-blue);
  --dp-footer: #0b1737;
  --dp-btn-hover: #10204c;
  --dp-widget-header: #026ed9;
  --dp-widget-header-text: white;

  //Maple
  --mp-navy: #181a4b;

  //NowPrepay
  --npp-app-green: #557937;
  --npp-orange: #ea632d;
  --npp-btn: #234a96;
  
  --max-container-width: 940px;
}

body {
  font-family: "Rubik", sans-serif !important;
  font-size: 1em;
  background-color: #ffffff !important;
}

#mainDeck1 {
  margin-right: 0 !important;
}

.spinner-border {
  margin: 10% 50% 0 calc(50% - 12px);
}

.whole-screen {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  height: 100vh;
}

/*This is for desktop (greater than cellphone based on iPhone 12 promax 486px) */
@media only screen and (min-width: 1025px) {
  .left-layout {
    width: 1%;
    flex: 1;
    padding-top: 2rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .right-layout {
    flex: 4;
    padding-top: 2rem;
    padding-bottom: 50px;
    padding-right: 25px;
  }

  .horizontal-layout {
    //padding-left: 25px;
    padding-right: 25px;
    display: flex;
    margin-top: 15px;
  }

  .div-center {
    padding-top: 2px;
  }
}

/* This is for desktop view. Left layout becomes too tight */
@media only screen and (min-width: 705px) and (max-width: 1024px) {
  .left-layout {
    width: 1%;
    flex: 1;
    padding-top: 2rem;
    padding-left: 2rem;
    padding-right: 3rem;
  }

  .right-layout {
    flex: 3;
    padding-top: 2rem;
    padding-bottom: 50px;
    padding-right: 25px;
  }

  .horizontal-layout {
    //padding-left: 25px;
    padding-right: 25px;
    display: flex;
    margin-top: 15px;
  }

  .div-center {
    padding-top: 2px;
  }
}

/*This is for mobile devices*/
@media only screen and (max-width: 704px) {
  .left-layout {
    display: none;
  }

  .horizontal-layout {
    margin-top: 1px;
    padding: 0.5rem 0.5rem 0.5rem;
  }

  .div-center {
    padding-top: 1px;
  }

  .right-layout {
    padding: 0.5rem !important;
    padding-top: 1.5rem !important;
  }

}

.div-center {
  text-align: left;
}

.hyperlink {
  color: #2b4e7b;
  cursor: pointer;
}

.hyperlink-alt {
  color: #1b1b1b !important;
  cursor: pointer !important;
  text-decoration: none !important;
}

.inactiveBreadcrumb .hyperlink {
  color: dimgrey !important;
  font-weight: bold;
  pointer-events: none !important;
  cursor: default !important;
}

.body-text-center {
  margin: auto;
  text-align: left;
}

/*Background colour for pulldown menu*/
.btn-primary {
  background-color: #2b4e7b !important;
}

/*Inherit from paysimply */

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* end */

//.flex-wrapper {
//    justify-content: space-between;
//    display: flex;
//    flex-direction: column;
//    min-height: 100vh;
//}

#content {
  padding-top: 0.25rem;
}

/*Accessibility*/

.visually-hidden {
  position: absolute;
  left: -10000px;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

#main-content {
  outline: none !important;
}

#main-content > div:not(#search-content, .hero, .contact-us-top ) {
  max-width: 1300px;
  margin: 0 auto;
}

#search-content {
  outline: none !important;
}
#categories-content {
  outline: none !important;
}
#footer-content {
  outline: none !important;
}

::-webkit-scrollbar {
  overflow: scroll;
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

a:hover{
  text-decoration: none !important;
}


// return home buttons
button {
  border-radius: 5px;
}
.default-button {
  background-color: #767f82 !important;
  color: white !important;
  font-weight: 400 !important;
  border: none !important;
}

.maple-button {
  background-color: var(--mp-navy) !important;
  color: white !important;
  font-weight: 400 !important;
  border: none !important;

  .maple-inactive {
    background-color: white !important;
    color: var(--mp-navy) !important
  }
}

.paysimply-button {
  background-color: var(--ps-btn) !important;
  color: white !important;
  font-weight: 400 !important;
  border: none !important;
}

.zenwallet-button {
  color: black;
  background-color: var(--zw-green) !important;
  border: none !important;
  font-weight: 400 !important;
}

.zenwallet-button:hover{
  text-decoration: none !important;
  color: black;
  background-color: var(--zw-green) !important;
  border: none !important;
}

.topmeup-button {
  background-color: var(--tm-green) !important;
  color: white !important;
  border: none !important;
  font-weight: 400 !important;
}

.nowprepay-button {
  background-color: var(--npp-btn) !important;
  border: none !important;
  font-weight: 400 !important;
  color: white !important;
}

.directpay-button {
  background-color: var(--dp-blue) !important;
  border: none !important;
  font-weight: 500 !important;
  color: white !important;
  &:hover {
    background-color: var(--dp-btn-hover) !important;
  }

}

.stop-using-ie {
  text-align: center;
  background-color: #ffc201;
  color: black;
  margin-bottom: 0px;
  border: none;
}

.stop-using-ie > a {
  color: #135796;
}
