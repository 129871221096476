.flex-box {
  display: flex;
}

.flex-crumbs {
  flex: 1;
}

.flex-search {
  padding-right: 2rem;
  width: 40%;
}

.section-heading {
  font-size: 2rem;
}

.article-link {
  padding-bottom: 1rem;
}

.flex-container {
  display: flex;
  flex-direction: column;
}

.flex-whitespace {
  width: 1rem;
  height: 0.25rem;
}

.section-description {
  font-size: 0.8rem;
  color: #434343;
  margin-bottom: 25px;
}

@media only screen and (max-width: 704px) {
  #section-heading {
    font-size: 1.7em;
  }

  .flex-box {
    display: block !important;
  }

  .flex-search {
    flex: 1;
    width: 100% !important;
    padding-left: 0.5rem;
    padding-right: 0.5rem !important;
  }
}
