#error-container {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  padding: 20px;
}

#error-container > * {
  margin-left: auto;
  margin-right: auto;
}

.error-go-home-btn {
  //color: black;
  font-weight: 600;
  border: none;
  margin-top: 25px;
}

#error-text {
  margin-top: 50px;
}



#not-found-container {
  display: flex;
  flex-direction: column;
  margin-top: 5rem;
  padding: 20px;
}

.ps-logo-error {
  max-width: 300px;
}

.xxl-font {
  font-size: xx-large;
}
