#hero > .overlay {
  background: rgba(0, 0, 0, 0.1);
  min-height: 300px;
}

.ecash-img-container {
  //height: 50px;
  overflow: hidden;
}

.ecash-img {
  height: 375px;
}
.eCashHero__title,
.eCashHero__subtitle {
  color: black;
  font-weight: 200 !important;
}

.eCashHero__buttons {
  margin-top: 2.75rem;
}

.eCashHero__buttons img {
  margin-left: 0 !important;
}

.eCashHero__title {
  text-align: left;
  font-size: 50px;
  margin-bottom: 4rem;
}

.eCashHero__subtitle {
  text-align: left;
  font-size: 18px;
  margin-bottom: 3.5em;
}

@media screen and (max-width: 995px) {
  .ecash-img {
    display: none;
  }

  .eCashHero__title {
    font-size: 45px;
    margin-bottom: 1rem;
  }
  .ecash-title-container {
    margin: 10px;
  }
  .eCashHero__subtitle {
    font-size: 17px;
    margin-bottom: 2rem;
  }
  .top-container {
    margin: 0 !important;
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }
  .eCashHero__buttons {
    margin-top: 1em;
  }


}

@media screen and (max-width: 400px) {
  .eCashHero__title {
    font-size: 29px;
    margin-bottom: 0.25em !important;
  }

  .eCashHero__subtitle {
    font-size: 15px;
    margin-bottom: 1rem;
  }
  .eCashHero__buttons {
    margin-top: 1em;
  }
  .overlay {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
}
