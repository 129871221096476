$widget-btn-width: 15px;

#widget-minimized {
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: column;
  align-content: space-between;
  z-index: 999;
  width: 100%;
  padding: var(--content-padding-widget);
}

#widget-minimized-mobile {
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 999;
  width: 100%;
  padding: var(--content-padding-widget);
  border-top-left-radius: var(--frame-border-radius);
  border-top-right-radius: var(--frame-border-radius);
}


#algolia-widget-mobile {
  flex: 1;
}

#widget-top-horizontal {
  display: flex;
  flex-direction: row;
  align-content: space-between;
}

.widget-back-btn, .widget-home-btn {
  border: 0;
  color: black;
  cursor: pointer;
  padding: 0;
}
.widget-back-btn:hover {
  color: black;
}

.widgetEmptySpan{
  width: $widget-btn-width;
}


.min-button {
  background-color: transparent;
  border: 0;
  padding: 0;
  margin: 0;
}

#widget-min-title {
  margin-left: auto;
  margin-right: auto;
  font-weight: 500;
}

#widget-title-mobile {
  font-weight: 500;
}

.widget-home-btn {
  //margin-left: -0.5rem;
  //padding: 1px;
  margin-right: -0.5px;
}

.center-text {
  margin-left: auto;
  margin-right: auto;
}

.flex-align-vertical {
  align-self: center;
}

#backButtonFlexSpan {
  display: flex;
}

.widget-min-btn {
  cursor: pointer;
  padding-bottom: 3px;
  margin-bottom: 2px;
  width: $widget-btn-width;
}

#filtering-search-label {
  margin-top: 10px;
  margin-bottom: -5px;
}

#filter-searching-articles-text {
  opacity: 0.9;
  font-size: 12px;
  font-weight: 500;
  display: none;
}

.tooltipText-minimize {
  --tooltip-width: 150px;
  visibility: hidden;
  width: var(--tooltip-width);
  background-color: #333;
  color: white;
  text-align: center;
  border-radius: 0.25rem;
  padding: 0.1rem;
  position: absolute;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
  bottom: 50%;
  right: var(--content-padding-widget);
  font-size: 0.8rem;

  &::after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 94%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #333 transparent;
  }

  *:focus + & {
    visibility: visible;
    opacity: 1;
  }

  *:hover + & {
    visibility: visible;
    opacity: 1;
  }
}

.tooltipText-back {
  --tooltip-width: 150px;
  visibility: hidden;
  width: var(--tooltip-width);
  background-color: #333;
  color: white;
  text-align: center;
  border-radius: 0.25rem;
  padding: 0.1rem;
  position: absolute;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
  bottom: 50%;
  left: var(--content-padding-widget);
  font-size: 0.8rem;

  &::after {
    content: '';
    position: absolute;
    bottom: 100%;
    right: 88%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #333 transparent;
  }

  *:focus + & {
    visibility: visible;
    opacity: 1;
  }

  *:hover + & {
    visibility: visible;
    opacity: 1;
  }
}
