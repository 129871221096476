.quick-app-card-title {
  font-size: 21px;
  font-weight: 500;
  padding-left: 15px;
  margin-top: auto;
  margin-bottom: -3px;
}

.card-container {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 20px !important;
  margin-bottom: 30px !important;
  padding-top: 15px;
}

.card-container.highlighted {
  border: 3px solid var(--npp-orange);
}

.card-container-paysimply{
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 20px !important;
  padding-top: 15px;
  max-width: 985px;
  margin: 0 !important;
}


.card-container-paysimply .np-card-text {
  margin-bottom: 20px;
}

.point-ul{
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 0;
  list-style: none;
  margin-left: -4px;
}
.point-ul > *{
  // make each element take up 50% of the width without overlapping
  flex: 1 1 calc(50% + 4px);
  font-size: 16px;
  width: 175px;
  opacity: 1;
  padding-bottom: 7px;
}

// bullet
.point-ul li::before{
  position: absolute;
  content: "\2022";
  display: inline;
  font-size: 27px;
  margin-left: -18px;
  margin-top: -8px;
}

// bullet colour
.orange-group .point-ul li::before {
  color: var(--npp-orange);
}

.green-group .point-ul li::before {
  color: var(--npp-app-green);
}

.nowprepay-app-grid {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 62px !important;
  margin-bottom: 50px !important;
  max-width: 900px !important;
  width: 70%;
}

.device-type {
  font-size: 14px;
  padding-top: 0.75em;
}

.orange {
  color: var(--npp-orange) !important;
}

.green {
  color: var(--npp-app-green) !important;
}

.red {
  color: var(--ps-red) !important;
}


.nowprepay-app-grid a {
  text-decoration: none;
  color: inherit;

}

.nowprepay-app-grid a:hover {
  text-decoration: none;
  color: inherit;

}

a.appLinkListItem {
  color: #0070E0;
}
a.appLinkListItem:hover, a.appLinkListItem:focus {
  color: #0056b3;
}

.newItem {
  position:relative;
  display:inline-block;
}

.newBadge {
  background-color: yellow;
  font-size: 11px;
  font-weight: bold;
  padding: 2px;
  position: absolute;
  top:-17px;
  left: 15px;
  text-align: center;
  border-radius: 5px;
}

.mb-0 {
  margin-bottom: 0;
}

.orange-button {
  color: black;
  background-color: var(--npp-orange);
}

.green-button {
  color: white;
  background-color: var(--npp-app-green) !important;
}

.red-button {
  color: white;
  background-color: var(--ps-red) !important;
}

.orange-button.active, #RetailerCentralBtn:active {
  background-color: #ac661b !important;
  border-width: 0 !important;
}

.green-button.active {
  background-color: var(--npp-app-green) !important;
  border-width: 0 !important;
}

.np-card-text {
  margin-left: 20px;
  margin-right: 20px;
  flex-grow: 1; /* Make this element take up all available vertical space */
}

.np-card-group {
  display: flex;
  flex-direction: row;
}

.orange-title-container {
  font-size: 11px;
  display: flex;
  flex-direction: row;
}

.body-vertical-container {
  display: flex;
  flex-direction: column;
}

.green-title-container {
  width: 100%;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  flex-direction: row;
  border: none;
}

.card-action {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  text-align: center;
  padding: 8px;
  font-weight: 500;
  font-size: 19px;
  transform: translateY(2px);
}


.np-card-body {
  margin: 0;
  padding: 0;
  padding-top: 5px !important;
  margin-left: 2px !important;
}

.body-vertical-container div {
  display: flex;
  flex-direction: row;
  margin-top: -5px;
}


.green-title-text-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 15px;
  margin-top: auto;
}

.lbl-ecash-merchant {
  font-size: 30px;
  font-weight: 500;
  margin-top: 28px;
  margin-bottom: 24px;
}

#card-deck-paysimply {
  margin-top: 2em;
  width: 100%;
}

@media screen and (max-width: 575px) {
  .lbl-ecash-merchant {
    font-size: 20px;
  }
}

// title row overlaps so change row to column
// FA auto responsive
@media screen and (max-width: 1212px) {

  .np-card-group {
    flex-direction: column;
  }

  .point-ul > * {
    font-size: 16px;
  }

  // Entire Card
  .card-container {
    padding-top: 15px !important;
  }

  .card-container,
  .card-container-paysimply {
    max-width: 550px;
    min-width: 350px;
    width: unset;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }


  .device-type {
    padding-top: 0.6em;
  }

  .green-title-container,
  .orange-title-container {
    padding-top: 5px;
  }

  #card-deck-paysimply {
    width: unset;
  }

}
