.skip-link-cont {
  top: 0;
  position: fixed;
  z-index: 9999;
  left: 50%;
  transform: translate(-50%, -100%);
  transition: transform 0.3s;
  width: 100%;
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
  width: 100%;
}

.skip-link-cont {
  width: 100%;
}

.load-skip-link-cont {
  display: table;
  margin: 0 auto;
}

.skip-link-cont:focus {
  transform: translateY(0%);
}

.skip-link-cont:focus-within {
  transform: translate(-50%, -10%);
}

.skip-sub-cont {
  background-color: black !important;
  padding: 5px;
  display: inline-block !important;
  border-radius: 5px;
  margin-left: 5px;
  border: 2px solid #ffffff;
}

.skip-link {
  color: #ffffff !important;
  font-size: 14pt !important;
  background-color: transparent !important;
  border: 0 !important;
}

.skip-sub-cont:hover {
  background-color: #555556 !important;
}
