.footer-style{
    width: 100%;
    bottom: 0;
    color: #b3b3b3;
    padding: 0.1rem 0.5rem;
    font-size: small;
    margin-top: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
.footer-links, .footer-links:hover {
    text-decoration: none;
}

#trademark-text {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

#footer-content span#footer-links *:not(:last-child)::after {
    content: ' • ';
    color: #b3b3b3;
}

.max-container-center {
    max-width: var(--max-container-width);
    margin: 0 auto;
}

#footer-text {
    text-align: center;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}

#maple-text {
    //margin-bottom: 0.25rem;
    white-space: pre-wrap;
    font-size: 11px;
    margin-top: 0.5rem;
}

#footer-links {
    font-size: 16px;
    margin-top: 5px;
}
