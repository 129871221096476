

@media only screen and (min-width: 500px) {
  .nowprepay-app-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .nowprepay-radio {
    .radio-card {
      margin-right: 15px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.nowprepay-app-div {
  width: 100%;
  margin-right: -10px !important;
  button {
    margin-left: 5px;
    margin-right: 5px;
  }
}

.nowprepay-radio {


  .select-option {
    color: black;
    border: 1px solid var(--npp-orange);
  }

  .contact-radio:checked ~ .select-option {
    color: black;
    background-color: var(--npp-orange) !important;
  }
}
