:root {
  --hyperlink-grey : rgb(40, 40, 40);
  --content-padding-widget: 1rem;
  --frame-width: 375;
  --frame-border-radius: 5px;
  --card-hover-box-style: -1px 0 5px 3px #d0d0d0;
  --widget-ps-red: #bb1b20;
  --widget-tm-green: #448003;
  --widget-zw-green: #6cc72b;
  --widget-np-orange: #f68d1e;
}


.widget-main-flex-container {
  display: flex;
  flex-direction: column;

}

.widget-main-card {
  padding: 0.5rem;

}

.widget-main-card:hover {
  box-shadow: var(--card-hover-box-style);
  transition: 0.3s;
}

.widget-main-link {
  color: var(--hyperlink-grey);
  margin-bottom: 15px;
}

.widget-main-link:hover {
   color: var(--hyperlink-grey);
   text-decoration: none;
 }

.title-text {
  margin-top: 0.5rem;
  font-weight: 500;
  font-size: larger;
  margin-bottom: 1rem;
}

.widget-section-description {
  margin-top: -1rem;
  margin-bottom: 1rem;
  color: #434343;
  font-size: 0.95rem;
}