#widget-language-flex-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.widget-language-card {
  padding: 0.5rem;

}
.widget-language-card:hover {
  box-shadow: var(--card-hover-box-style);
  transition: 0.3s;

}

.widget-language-link  {
  margin-bottom: 1rem;
  color: var(--hyperlink-grey);
}

.widget-language-link:hover  {
  color: var(--hyperlink-grey);
  text-decoration: none;

}