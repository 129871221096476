.topmeup-web-mobile {
  Button {
    border-color: var(--tm-green) 1px;
  }

  .inactive-btn,
  Button {
    color: #4e7d2d !important;
    border-color: #4e7d2d !important;
  }

  .active-btn {
    color: white !important;
    background-color: var(--tm-green) !important;
  }
}

// radio color
.topmeup-radio {

  .select-option {
    color: var(--tm-green);
    border: 1px solid var(--tm-green);
  }

  .contact-radio:checked ~ .select-option {
    color: white;
    background-color: var(--tm-green) !important;
  }
}