.mini-searchBox {
  display: flex;
  position: relative;
  justify-content: center;
  margin-top: 0.5rem;
}

.ais-SearchBox-form {
  line-height: normal !important;
  display: inline-flex !important;
  height: 2.5rem !important;
  border-radius: var(--border-radius-size);
}

.ais-SearchBox-input {
  box-shadow: none !important;
  border: transparent !important;
  border-radius: 6px;
  
  &::placeholder {
  color: #707070;
  }

}

.ais-SearchBox-reset {
  display: none;
}

.ais-SearchBox-resetIcon {
  padding-right: 10px;
}

.searchBox {
  border-radius: var(--border-radius-size) !important;
  width: 95%;
  margin-left: 2.5%;
  margin-right: 2.5%;
}

.algolia-search-result-pulldown {
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.2);
  color: black !important;
  backdrop-filter: blur(10px);
  border-radius: var(--border-radius-size);
  width: 95%;
  margin-left: 2.5%;
  margin-right: 2.5%;
  position: absolute;
}

.widget-search-result-card {
  color: var(--hyperlink-grey);
  margin: 3px 2px;
  font-size: small;
  transition: 0.3s;
  padding: 0.4rem;
  padding-left: 0.5rem;
  display: flex;
  flex-direction: column;
}

.widget-search-result-card:hover {
  box-shadow: -1px 0 5px 3px #b4b4b4;
  transition: 0.3s;
}

.widget-algolia-section {
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0.1px;
  opacity: 0.8;
  margin-bottom: 3px;
}

// chevron
.algolia-header-separator {
  margin-left: 5px;
  margin-right: 5px;
}

.search-result-card {
  border: rgba(0, 0, 0, 0.125);
  padding: 1rem;
  text-align: left;
}

.search-result-card:hover {
  box-shadow: -1px 0 5px 3px rgba(0, 0, 0, 0.25);
}

.algolia-search-hit-items:hover {
  text-decoration: none;
}

.algolia-search-hit-items {
  margin-top: 5px;
  background-color: white;
  color: white;
  padding-bottom: 500px;
  border: solid;
}

.result-chevron {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  align-self: center;
}

.no-decoration:hover {
  text-decoration: none;
  color: black;
}
.no-decoration {
  text-decoration: none;
  color: black;
}

// ENTIRE ALGOLIA SEARCH BAR + RESULT
.ais-InstantSearch {
  z-index: 9999;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: 800px !important;

  //margin-left: auto;
  //margin-right: auto;
}

// RIGHT SIDE SEARCH BTN
.ais-SearchBox-submit {
  display: none !important;
}

.bordered {
  border: 1.5px solid #c4c4c4 !important;
  border-radius: var(--border-radius-size);
}

#search-icon:hover {
  cursor:default;
}
