:root {
  --first-indent-margin: 20px;
  --second-indent-margin: calc(var(--first-indent-margin) + 20px);
  --negative-space-list-to-para: -15px;
  --negative-list-margin-top: -15px;
}

#article-content {
  display: inline-block;
  font-size: 1rem;
  text-align: left;
  margin-top: 0.5rem;
  margin-left: 0.2rem;
}

@media only screen and (min-width: 705px) {
  #article-title {
    font-size: 30px;
  }
}

@media only screen and (max-width: 704px) {
  #article-title {
    font-size: 25px;
  }

  .flex-box {
    display: block !important;
  }

  .flex-search {
    flex: 1;
    width: 100% !important;
    padding-left: 0.5rem;
    padding-right: 0.5rem !important;
  }
}

#bottom-stuff {
  /**position: absolute;
    padding-bottom: 2em;
    color: rgba(0,0,0,0.5);
    */
  display: flex;
  color: #555556;
  font-size: smaller;
  padding-bottom: 50px;
  padding-top: 50px;
  flex-direction: column;
}

#article-layout {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  max-width: 800px;
  left: 15%;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 704px) {
  #article-layout {
    padding: 2rem 1rem;
  }
}

.flex-box {
  display: flex;
}

.flex-crumbs {
  flex: 1;
}

.flex-search {
  padding-right: 2rem;
  width: 40%;
}


#last-update-text {
  margin-top: 2vh;
}

#cover {
  font-size: x-large;
}

//// if p tag in list https://help.paymentsource.net/paysimply/en/articles/49-i-received-an-error-message-when-trying-to-pay-by-
#article-content li > p {
  margin:0;
}

// new markdown
// if OL not preceded by P, then no padding
#article-content > ol {
  padding-left: 15px;
}

// for ordered/unordered lists under paragraphs
#article-content > p+ul, #article-content > p+ol {
  margin-left: var(--first-indent-margin);
  margin-top: var(--negative-space-list-to-para);
}

// for unordered lists. Markdown renders them as ol > li > p
#article-content ol > li > p{
  margin: 0;
}

// for ordered/unordered list in ordered/unordered list
#article-content ul+ol, #article-content ol+ul {
  margin-top: var(--negative-list-margin-top);
  margin-left: var(--second-indent-margin);
}

// if ol is a direct descendent of li
#article-content ol>li, #article-content li>ol {
  padding: 0;
  margin-left: 10px;
}


// Change Heading size
#article-content {
  h1 {
    display: none !important;
    font-size: 2.0rem;
  }
  h2 {font-size: 1.8rem;}
  h3 {font-size: 1.6rem;}
  h4 {font-size: 1.4rem;}
  h5 {font-size: 1.2rem;}
  h6 {font-size: 1.0rem;}
}
