.paymentsource-image {
  width: 10rem;
  margin-bottom: 20px;
}


.logo-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


.home-table {
  padding: 0.5rem;
}

#help-text {
  padding-bottom: 1.5rem;
  font-weight: 400;
}


#logo-table {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.home-card-style:hover {
  box-shadow: -1px 0 5px 3px #d0d0d0;
}

// mobile
@media screen and (max-width: 704px) {
  #home-top-div {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  #home-mobile {
    margin-left: auto;
    margin-right: auto;
  }
  .home-card-style {
    min-width: 17rem;
    margin-top: 0.5rem;
    max-height: 15rem;
  }
  #home-desktop {
    display: none;
    visibility: hidden;
  }

}

// desktop
@media screen and (min-width: 705px) {
  .home-card-style {
    max-width: 17rem;
  }
  #home-mobile {
    display: none;
    visibility: hidden;
  }
  .card-img {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
}

.home-page {
  margin-left: auto;
  margin-right: auto;
  margin-top: 25px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  justify-content: center;
}


table.center {
  margin-left: auto;
  margin-right: auto;
}

.brand-card-body {
  height: 170px !important;
  padding: 15px;
}

.debug-widget {
  align-items: center;
  justify-content: center;
  margin-bottom: 10px !important;
}

.widget-debug-input {
  margin-right: 5px;
  height: 30px;
  min-width: 650px;
}

.widget-test-title {
  font-size: 20px;
}

#debug-hidden-brands .card-img {
  width: 50% !important;
}

#home-debug-table {
  margin-left: auto;
  margin-right: auto;
}
