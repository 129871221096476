.dp-choose-container {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.dp-choose-container .card {
  margin-top: 10px;
  padding: 0;
  cursor: pointer;
  border-radius: 5px;
  max-width: 300px;
}

@media screen and (max-width: 575px) {
  .dp-choose-container {
    flex-direction: column;
    align-items: center;
  }
  .dp-choose-container .card {
    margin-top: 10px;
    padding: 0;
    cursor: pointer;
    border-radius: 5px;
    width: 80%;
  }
}

.dp-choose-container .card:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
  transition: 0.3s;
}

.user-select {
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
}

.contact-us-heading {
  padding-bottom: 20px;
}

.dp-main-form-content {
  margin-top: 50px;
}

.dp-chevron {
  margin-right: 6px;
}

.dp-blue {
  color: var(--dp-blue);
}

.dp-green {
  color: var(--dp-green);
}

.back-button {
  background-color: transparent;
  padding: 5px 10px;
  border-radius: 4px;
  border: solid var(--dp-blue) 0.5px;
  color: black;
}

.back-button:hover {
  color: white;
  transition: 0.3s;
  background-color: var(--dp-blue);
}

.merchant-form,
.customer-form {
  margin-top: 50px;
  max-width: 700px;
}

.merchant-form label,
.customer-form label {
  font-size: 1.2em;
  font-weight: 400;
}

.submit-button {
  margin-top: 15px;
  padding: 0.8em;
}



#lbl-phone-number {
  margin-bottom: 0;
}


@media screen and (max-width: 1300px) and (min-width: 501px){
  .merchant-form,
  .customer-form {
    padding: 30px 16px 50px 16px;
    margin-top: 10px;
  }
}

@media screen and (max-width: 500px) {
  .user-select {
    margin-top: 0px;
  }
  .merchant-form,
  .customer-form {
    margin-top: 0px;
  }
  .dp-main-form-content {
    margin-top: 15px;
  }

  }
#dp-successMessage {
  font-size: 1em;
  display: none;
  margin-left: auto;
  margin-right: auto;
  margin-top: 25vh;
  text-align: center;
  padding: 15px;
}
