.article-list {
  flex-wrap: wrap;
}


ul {
  padding-left: 20px;
}

.section-title {
  font-weight: 500;
  color: rgb(40, 71, 112);
}

.section-title:hover {
  text-decoration: none;
}

#category-title {
  //font-size: 1.8rem;
  font-size: xx-large;
  margin-bottom: 25px;
}

.section-category-list {
  margin-bottom: 1.7rem;
}

#section-category-text {
  font-size: 1.2rem;
  margin-bottom: 1.7rem;
}

/**
.articleListClass{
  margin-bottom: 5%;
}

*/

.section-title-container {
  padding-top: 1rem;
  font-size: x-large;
  margin-bottom: 0.6rem !important;
}

.dropdown-button {
  color: #555556 !important;
  border-color: #555556 !important;
}

.dropdown-button:hover,
.dropdown-button:focus,
.dropdown-button:active {
  color: white !important;
  border-color: white !important;
  border-color: #555556 !important;
  background-color: #555556;
}

.dropdown-button:not(:disabled):not(.disabled).active,
.dropdown-button:not(:disabled):not(.disabled):active,
.show > .dropdown-button.dropdown-toggle {
  color: #fff !important;
  background-color: #555556;
  border-color: #555556;
}

.custom-dropdown-item {
  color: rgb(43, 78, 123) !important;
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.custom-dropdown-item:hover,
.custom-dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #e9ecef;
}

@media only screen and (min-width: 705px) {
  #section-pull-down {
    display: none;
  }
  .article-list-text {
    padding-bottom: 0.5rem;
  }

}

@media only screen and (max-width: 704px) {
  #section-pull-down {
    display: inline;
    visibility: visible;
  }

  .section-in-category-list {
    margin-bottom: 0.1rem;
  }
  #category-title {
    font-size: xx-large;
    padding-top: 1rem;
  }
  .article-list-text {
    padding-bottom: 0.25rem;
  }



}

#dropdown {
  font-size: 0.9rem;
  padding: 5px;
}

.flex-container {
  display: flex;
  flex-direction: column;
}
//.flex-whitespace {
//  width: 1rem;
//  height: 0.75rem;
//}

.section-link {
  font-size: 0.8rem;
  margin-bottom: 1.5rem;
}

.list-section-description {
  font-size: 0.8rem;
  color: #434343;
  margin-bottom: 15px;
  //font-style: italic;
}