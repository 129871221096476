#desktop-contact-us {
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 800px !important;
}

@media only screen and (max-width: 704px) {
  #desktop-contact-us {
    padding: 30px 1rem 50px 1rem;
  }
}

#desktop-contact-us h1 {
  margin-bottom: 0px;
  font-size: xx-large;
}

.form-labels {
  font-size: 1.3em;
  font-weight: 400;
}

#submit-button {
  width: 100%;
  margin-top: 1rem;
}

.muted {
  font-weight: 400;
  margin-top: 0px;
}

.error-handling-messages {
  font-size: 1em;
  color: red;
  display: none;
}
.form-groups {
  margin-top: 30px;
}

.errorMessage {
  font-size: 1em;
  color: red;
}

.clickable-btn {
  border: 1px solid #2b4e7b;
  box-sizing: border-box;
  border-radius: 5px;
}

.active-btn {
  background-color: #2b4e7b;
  color: #ffffff;
}

.inactive-btn {
  background-color: #ffffff !important;
  color: #2b4e7b;
}

.inactive-btn:hover,
.inactive-btn:focus,
.inactive-btn:active {
  color: #2b4e7b !important;
}

// icon beside button with text
.btn-icon {
  margin-right: 10px;
}

.contact-secondary-btn {
  border: solid;
}


.web-mobile-btn {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  .form-check {
    padding-left: 0;
    margin-right: 10px !important;
  }
}

#contact-mobile-btn, #contact-web-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
}

#dropdown-btn,
#ContactForm_PaymentOption_ {
  max-width: 700px;
  width: 100%;
}

.dropdown-button {
  color: #555556 !important;
  border-color: #555556 !important;
}

.dropdown-button:hover,
.dropdown-button:focus,
.dropdown-button:active {
  color: white !important;
  border-color: white !important;
  background-color: #555556;
}

.dropdown-button:not(:disabled):not(.disabled).active,
.dropdown-button:not(:disabled):not(.disabled):active,
.show > .dropdown-button.dropdown-toggle {
  color: #fff !important;
  background-color: #555556;
  border-color: #555556;
}

.email-help {
  margin-bottom: 0.55rem;
  margin-top: -0.8rem;
}

.error-handling-messages {
  font-size: 80%;
  color: #dc3545;
}

#successMessage,
#card-usage-message {
  display: none;
  margin: 50px auto 0 auto !important;
  visibility: hidden;
  text-align: center;
  padding: 15px;
  h1 {
    font-size: 2rem !important;
  }
}

#mapleSuccessMessage {
  margin: 50px auto 0 auto !important;
  text-align: center;
  padding: 15px;
  h1 {
    font-size: 2rem !important;
  }
}

.contact-label-suggestion {
  margin-top: -3px;
  margin-bottom: 5px;
  color: #6c757d !important;
  font-size: 0.8rem;
}

// Radio button

.radio-card {
  border: none;
  flex-grow: 1;
  margin-bottom: 10px;
}

@supports(-webkit-appearance: none) or (-moz-appearance: none) {
  .contact-radio {
    -webkit-appearance: none;
    -moz-appearance: none;

  }

  .radio-card:hover .radio {
    border-color: black;
    &:checked {
      border-color: white;
    }

  }
}

.select-option {
  border: 1px solid var(--ps-btn);
  border-radius: 0.5em;
  cursor: pointer;
  padding: 0.75em;
  transition:0.2s ease-out;

}

// when radio is selected
.contact-radio:checked ~ .select-option {
  color: white;
  background-color: var(--contact-radio-color);
  transition: 0.2s ease-in;
}

.contact-radio:focus ~ .select-option {
  box-shadow: 0px 0px 0px 4px rgba(172,206,247,1);
}

.email-banner {
  background-color:yellow;
}

.paymentButton {
  height: 2rem;
  &:hover {
    cursor: pointer;
  }
}

.btn-radio {
  display: none !important;
}
