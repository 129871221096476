.zenwallet-web-mobile {
  .inactive-btn,
  .active-btn,
  .zenwallet-web-mobile Button {
    color: black !important;
    border-color: var(--zw-green) !important;
  }

  .active-btn {
    color: black !important;
    background-color: var(--zw-green) !important;
  }

}

.zenwallet-radio {

  .select-option {
    color: black;
    border: 1px solid var(--zw-green);
  }

  .contact-radio:checked ~ .select-option {
    color: black;
    background-color: var(--zw-green) !important;
  }
  .card_images {
    height: 30px;
    width: auto;
    display: inline;
    margin-right: 10px;
  }
}
