.maple-web-mobile-btn {
  .active-btn {
    background-color: var(--mp-navy) !important;
    border: solid var(--mp-navy) 1px !important;
    color: white !important;
  }

  .inactive-btn {
    border: solid var(--mp-navy) 1px !important;
  }
}

.maple-radio {
  .contact-radio:checked ~ .select-option {
    color: white;
    background-color: var(--mp-navy);
    transition: 0.2s ease-in;
  }
  .contact-radio:focus ~ .select-option, button:focus {
    box-shadow: 0px 0px 0px 4px rgba(24, 26, 75, 0.8);
  }

  .select-option {
    border: 1px solid var(--mp-navy);
  }
}

.maple-showExampleCard-btn {
  border: none;
  background: transparent;
  color: rgb(43, 78, 123);
  padding: 0;
  text-align: start;
  margin-bottom: 5px;
  font-size: 15px;
}

.maple-phoneNumber-format {
  opacity: 0.8;
  font-size: 0.8em;
  margin: 0;
}

.linkIssues-fieldset {
  .form-labels {
    margin-top: 15px;
  }

  .form-control {
    max-width: 100% !important;
    width: 100%;
  }

  .form-phoneNumber {
    margin-bottom: 0;
  }

  .maple-exampleCard-img {
    width: 100%;
    max-width: 300px;
    display: block;
  }

  label {
    display: block;
  }
}

.maple-form-error-message {
  color: red;
}

.maple-app-select-btn {
  height: 38px;
}