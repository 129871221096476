.widget-article-title {
  font-size: larger;
  margin-bottom: 1.5rem;
  text-justify: inter-word;
  font-weight: 500;

}

#widget-ext-link {
  right: 0;
  top: 0;
  //margin-top: 0.5rem;
  margin-left: 0.5rem;
  height: 30px;
}

#widget-link-icon {
  color: var(--hyperlink-grey);
}

#widget-article-text {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  //font-size: smaller;
}

#widget-article-flex-container {
  display: flex;
  flex-direction: column;
}

#widget-title-link-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.widget-article-content {
  font-weight: 400;
}

.widget-article-content img {
  width: 100% !important;
}

#thumbs span {
  cursor: pointer;
}

#widget-breadcrumb {
  display: flex;
  align-items: center;
}

.widget-breadcrumb__text {
  font-size: smaller;
  color: var(--hyperlink-grey);
  margin-bottom: 0;
}

.widget-breadcrumb__chevron {
  margin-left: 5px;
  margin-right: 5px;
}
