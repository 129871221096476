#widget-footer-container {
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: row-reverse;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-right: 0.75rem;
  width: 100%;
  box-shadow: 2px 4px 8px 0 rgba(0,0,0,0.7);
  margin-top: auto;
  background-color: white;
}